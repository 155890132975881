.notification {
  position: relative;
}

.notification-button-container {
  margin-right: 20px;
  cursor: pointer;
  position: relative;

  &__unread-meassage-button {
    position: absolute;
    background: #ea6560;
    border-radius: 10px;
    top: 0;
    right: -8px;
    padding: 4px 5.5px;
  }

  &__unread-meassage-button-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    color: #fff;
  }
}

.notification-menu {
  background: #ffffff;
  box-shadow: 0px 0px 34px rgba(188, 193, 203, 0.23);
  border-radius: 20px;
  position: absolute;
  right: 10px;
  min-width: 300px;

  &__header {
    padding: 20px;
  }

  &__header-line {
    background: rgba(145, 158, 171, 0.24);
    height: 1px;
  }

  &__header-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #212b36;
  }

  &__header-subtitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #92929c;
    margin-top: 4px;
  }

  &__new-notif {
    padding: 12px 20px;
    background: #f2f4f8;
    margin: 2px 0px;
  }

  &__section-title {
    padding: 12px 20px;
    color: #92929c;
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
  }

  &__section-message {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: '#44444E';
    cursor: pointer;

    &:hover {
      color: #1891e5;
    }
  }

  &__section-time {
    display: flex;
    align-items: center;
    margin-top: 6px;
  }

  &__section-time-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #92929c;
    margin-left: 6px;
  }

  &__button {
    margin-top: 0px !important;
    font-weight: 600;
    font-size: 13px !important;
  }
}
